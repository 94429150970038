@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap')

*
    padding: 0
    margin: 0

$accent-color: #5F829B
$bg: white
$menubar-height: 73px
html
    height: 100vh
body
    font-family: 'Open Sans', sans-serif
    font-size: 1em
    background-color: $bg
    height: calc( 100vh - $menubar-height)
    overflow: hidden
    box-sizing: border-box
    @media screen and ( max-width: 700px )
        overflow-y: visible
        height: auto 
        
.header
    display: grid
    grid-template-columns: 230px 1fr
    @media screen and ( min-width: 840px )
        height: $menubar-height
    @media screen and ( max-width: 840px )
        grid-template-columns: 1fr
        grid-template-rows: auto
.logo
    display: flex
    align-items: center
    height: $menubar-height
    z-index: 1
    img
        max-width: 250px
        position: relative
        top: 10px
        left: 25px
        @media screen and ( max-width: 870px )
            width: 80%
            top: 0
            padding-left: 5px
        @media screen and ( max-width: 360px )
            max-width: 180px

.close
    width: 30%
    height: $menubar-height
    display: block
    position: absolute
    right: 0
    display: flex
    align-items: center
    justify-content: flex-end
    padding: 0 30px
    box-sizing: border-box
    z-index: 2
    @media screen and ( min-width: 840px )
        display: none

.hamburger
    width: 50px
    height: 32px
    display: flex
    flex-direction: column
    justify-content: space-between
    span
        width: 100%
        background-color: $accent-color
        height: 2px
        display: block
        
.menu
    display: flex
    align-items: center
    justify-content: space-between
    list-style: none
    background-color: $accent-color
    padding: 10px 60px 10px 130px
    box-sizing: border-box
    position: relative
    transform-origin: 0 0
    transition: 0.2s ease-in-out
    @media screen and ( max-width: 1000px )
        padding: 10px 20px 10px 100px
    @media screen and ( max-width: 840px )
        transform: scaleY(0)
        transform-origin: top center
        height: 0
        opacity: 0
        padding: 0
        transition: 0.2s ease-in-out
        align-items: flex-start
        justify-content: flex-start
        flex-direction: column
        & .menu__item
            width: 100%
            position: relative
            &::after
                content:""
                width: 100%
                height: 2px
                background-color: #769AB4
                position: absolute
                left: 0
                top: 50%
                z-index: 1
        & .menu__link
            position: relative
            z-index: 2
            display: inline-block
            height: 100%
            background-color: $accent-color
            transition: 0.2s ease
            padding: 5px 20px 5px 5px
            &::after
                content: ""
                display: inline-block
                width: 8px
                height: 8px
                border-radius: 150px
                background-color: white
                position: relative
                left: 10px
        &.active
            height: 100%
            padding: 20px
            opacity: 1
            transform: scaleY(1)
            transition: 0.1s ease

    &::before
        content: ""
        width: 100px
        height: 106%
        position: absolute
        left: -33px
        top: 0
        background-color: $bg
        transform: skewX(173grad)
        @media screen and ( max-width: 840px )
            display: none

.menu__item
    &.dot
        width: 10px
        height: 10px
        background-color: white
        border-radius: 150px
        @media screen and ( max-width: 1000px )
            width: 5px
            height: 5px
        @media screen and ( max-width: 870px )
            display: none
    @media screen and ( max-width: 870px )
        &:not(:last-child)
            margin-bottom: 20px
    a
        text-decoration: none
        color: white 

// CONTENT
.content
    display: grid
    grid-template-columns: 1fr 40%
    height: 100%
    box-sizing: border-box
    @media screen and ( max-width: 700px )
        grid-template-columns: 1fr
        grid-template-rows: 450px 1fr
        
h1
    text-transform: uppercase
    color: black
    font-size: 1.2em
    background-color: white
    padding: 8px 10px
    margin-bottom: 10px

.content__info
    padding: 60px
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100%
    @media screen and ( max-width: 570px )
        padding: 20px
        

.content__data
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    height: 100%
    z-index: 2
    
.text
    font-weight: 300
    font-size: 4em
    margin-bottom: 20px
    background: white
    box-decoration-break: clone
    display: inline
    box-sizing: border-box
    padding: 2px
    position: relative
    @media screen and ( max-width: 1320px )
        font-size: 3em
    @media screen and ( max-width: 980px )
        font-size: 2.3em
    @media screen and ( max-width: 720px )
        font-size: 2em
        padding: 10px
button
    background-color: $accent-color
    padding: 8px 20px
    border: none
    border-radius: 6px
    font-size: 1.6em
    color: white
    @media screen and ( max-width: 570px )
        margin-bottom: 20px
        
.copyright
    color: #5E5E5E

.content__slide
    height: 100%
    position: relative
    @media screen and ( max-width: 700px )
        grid-row: 1 / 2
        overflow: visible
    img
        transition: 0.5s ease-in-out
        width: 100%
        height: 100%
        object-fit: cover
        position: absolute
        opacity: 0
        animation: imageAnimation 12s linear infinite 0s
        &:nth-child(1)
            animation-delay: 8s
        &:nth-child(2)
            animation-delay: 4s
        &:nth-child(3)
            opacity: 1

        @media screen and ( max-width: 700px )
            height: 132%
            z-index: -1



@keyframes imageAnimation
    0%
        opacity: 0
        -webkit-animation-timing-function: ease-in
    8%
        opacity: 1
    17% 
        opacity: 1
    60%
        opacity: 0
    100%
        opacity: 0